import { Tag, Tooltip } from 'antd';
import { blue } from '@ant-design/colors';
import { format } from 'date-fns';
import { FormattedMessage } from 'react-intl';
import { Instance } from 'types/cluster';

function ClusterMoreInfoColumnRenderer({ instance }: { instance: Instance }) {
  const content = (
    <div>
      {instance.featureBits.map(featureBit => (
        <p key={featureBit.featureBits ? featureBit.featureBits.key : ''}>
          {featureBit.featureBits ? featureBit.featureBits.name : ''}
        </p>
      ))}
    </div>
  );

  return (
    <span>
      {instance.domain && (
        <Tooltip title={'Cluster Sub Domain'}>
          <Tag color="red">
            {instance.domain[0].toUpperCase() + instance.domain.substring(1)}
          </Tag>
        </Tooltip>
      )}
      {!instance.vanillaVersion && <Tag color="magenta">Upgraded</Tag>}
      {instance.upgrade && (
        <Tag color="orange">
          {instance.upgrade === true ? 'Pending Upgrade' : instance.upgrade}
        </Tag>
      )}
      {instance.isProvision && <Tag color="volcano">Provisioned</Tag>}
      {instance.filestoreMountPath && <Tag color="red">Filestore</Tag>}

      {instance.enableDeltaShare && <Tag color="cyan">Delta Sharing</Tag>}
      {instance.sqliEnabled && <Tag color="cyan">SQLI</Tag>}
      {instance.incortaXEnabled && <Tag color="cyan">SQLX</Tag>}
      {instance.rgName && instance.rgName !== '0' && (
        <Tag color="purple">{instance.rgName}</Tag>
      )}
      {instance.cname && <Tag color="purple">CName</Tag>}
      {instance.hostName && <Tag color="purple">Host</Tag>}
      {instance.enableDataAgent && <Tag color="cyan">Data Agent</Tag>}
      {!instance.sleeppable && <Tag color="red">Always Alive</Tag>}
      {instance.isCloned && <Tag color="orange">Clone</Tag>}
      {instance.mlflowEnabled && <Tag color="purple">MLflow</Tag>}
      {instance.enableOpenAI && <Tag color="blue">Copilot</Tag>}
      {instance.isMigrated && <Tag color="orange">Migrate</Tag>}
      {instance.isArchived && <Tag color="purple">Archived</Tag>}
      {instance.analyticsNodes > 1 && (
        <Tag color="volcano">{instance.analyticsNodes} Analytics</Tag>
      )}
      {instance.loaderNodes > 1 && (
        <Tag color="volcano">{instance.loaderNodes} Loaders</Tag>
      )}
      <Tooltip title={content}>
        <Tag color="green">Feature bits</Tag>
      </Tooltip>
      {instance.rImage && <Tag color="magenta">R Image</Tag>}
      {instance.isExcludedOc && <Tag color="volcano">OC Excluded</Tag>}
      {instance.InstanceChidori?.isChidori && <Tag color="orange">Chidori</Tag>}
      {instance.onDemandLoader && instance.onDemandLoader.enabled && (
        <Tag color="blue">On-Demand Loader</Tag>
      )}
      {instance.enableBusinessUserNotebook && (
        <Tag color="gold">Business User Notebook</Tag>
      )}
      {instance.deletedAt && (
        <Tooltip
          title={format(new Date(instance.deletedAt), 'dd-MM-yyyy hh:mm a')}
        >
          <Tag color="black">Deleted</Tag>
        </Tooltip>
      )}
      {(instance.externalBucket || instance.configuredAsDist) && (
        <Tooltip
          title={`External Bucket: ${instance.externalBucket ? 'Source' : 'Destination'
            }`}
        >
          <Tag color={blue[1]} style={{ color: blue[8], borderColor: blue[3] }}>
            <FormattedMessage id="clusterPage.displayVersions" />
          </Tag>
        </Tooltip>
      )}
      {instance.incortaSparkVersion && (
        <Tooltip
          title={instance.incortaSparkVersion}
        >
          <Tag color="blue">Spark Version</Tag>
        </Tooltip>
      )}
    </span>
  );
}

export default ClusterMoreInfoColumnRenderer;
