import React, { useContext } from 'react';
import { Modal } from 'antd';
import ClusterModal from 'components/Clusters/ClusterModal/ClusterModal';
import UpgradeModal from 'components/Clusters/UpgradeModal/UpgradeModal';
import CloneModal from 'components/Clusters/CloneModal/CloneModal';
import MigrateModal from 'components/Clusters/MigrateModal/MigrateModal';
import LogsModal from 'components/Clusters/LogsModal/LogsModal';
import LdapModal from 'components/Clusters/LdapModal/LdapModal';
import TransferOwnershipModel from 'components/Clusters/TransferOwnershipModel/TransferOwnershipModel';
import ProgressLogs from 'components/Clusters/ProgressLogs/porgressLogs';
import FeatureBitsActionsModal from 'components/Clusters/FeatureBitsActionsModal/FeatureBitsActionsModal';
import Secrets from 'components/Clusters/Secrets/secrets';
import ClusterServiceStatus from 'components/Clusters/ClusterServiceStatus/clusterServiceStatus';
import CustomizeModal from 'components/Clusters/CustomizeModal/CustomizeModal';
import SlaCostModal from 'components/Clusters/SlaCostModal/SlaCostModal';
import InstanceUsers from 'components/Clusters/InstanceUsers/InstanceUsers';
import ClusterMaintenanceModal from 'components/Clusters/ClusterMaintenanceModal/ClusterMaintenanceModal';
import ErrorModal from 'components/Clusters/ErrorModal/ErrorModal';
import SchedulerModal from 'components/Clusters/SchedulerModal/SchedulerModal';
import LockInterfaceModal from 'components/Clusters/LockActions/LockInterfaceModal';
import SparkMasterModal from 'components/Clusters/SparkMaster/SparkMaster';
import ChidoriIcon from 'images/chidori-icon.svg';
import NotebookIcon from 'images/notebook-logo.svg';
import AuditHistory from 'components/AuditHistory/AuditHistory';
import AutoScalingModal from 'components/Clusters/AutoScalingModal/AutoScalingModal';
import DiskUpsizeModal from 'components/Clusters/DiskUpsize/DiskUpsizeModal';
import { SessionContext } from 'auth/SessionProvider';
import { ResponseMessage, modalKeys } from 'components/Clusters/helper';
import { Instance } from 'types/cluster';
import {
  ActiveModalContext,
  ModalCountContext,
  SetActiveModalContext,
  SetModalErrorContext,
} from 'components/Clusters/contexts/ClusterContext';
import NotebookModal from 'components/Clusters/NotebookModal/NotebookModal';
import ExternalBucketModal from 'components/Clusters/ExternalBucketModal/ExternalBucketModal';

function ClustersModalsWrapper({
  instance,
  selectedRowKeys,
  refetchClusters,
  handleShowErrorModal,
}: {
  instance: Instance;
  selectedRowKeys: string[];
  refetchClusters: Function;
  handleShowErrorModal: ({
    message,
    responseMessages,
  }: {
    message: string;
    responseMessages: ResponseMessage;
  }) => void;
}) {
  const { user } = useContext(SessionContext);
  const activeModal = useContext(ActiveModalContext);
  const setActiveModal = useContext(SetActiveModalContext);
  const setError = useContext(SetModalErrorContext);
  const modalCount = useContext(ModalCountContext);

  function handleCloseModal() {
    setActiveModal(null);
  }

  switch (activeModal) {
    case modalKeys.EDIT_CLUSTER:
      return (
        <ClusterModal
          isNew={false}
          initialCluster={instance}
          close={handleCloseModal}
          refetchClusters={refetchClusters}
        />
      );
    case modalKeys.CLUSTER_INFO:
      return (
        <Modal
          title="Cluster Info"
          open={true}
          onCancel={handleCloseModal}
          footer={null}
          width={'50%'}
        >
          <ClusterServiceStatus instance={instance} />
        </Modal>
      );
    case modalKeys.UPGRADE:
      return (
        <UpgradeModal
          isNew={false}
          cluster={modalCount === 'single' ? instance : null}
          clusterName={modalCount === 'single' ? instance.name : null}
          clusterIds={modalCount === 'multiple' ? selectedRowKeys : null}
          refetchClusters={refetchClusters}
          close={handleCloseModal}
          handleShowErrorModal={handleShowErrorModal}
        />
      );
    case modalKeys.CLONE:
      return (
        <CloneModal
          isNew={false}
          cluster={instance}
          refetchClusters={refetchClusters}
          close={handleCloseModal}
        />
      );
    case modalKeys.MIGRATION:
      return (
        <MigrateModal
          isNew={false}
          cluster={instance}
          refetchClusters={refetchClusters}
          close={handleCloseModal}
        />
      );
    case modalKeys.LOGS:
      return (
        <LogsModal
          cluster={instance}
          refetchClusters={refetchClusters}
          close={handleCloseModal}
        />
      );
    case modalKeys.LDAP:
      return (
        <LdapModal
          isNew={false}
          cluster={instance}
          refetchClusters={refetchClusters}
          close={handleCloseModal}
        />
      );
    case modalKeys.TRANSFER_OWNERSHIP:
      return (
        <TransferOwnershipModel
          cluster={modalCount === 'single' ? instance : null}
          clusterIds={modalCount === 'single' ? [instance.id] : selectedRowKeys}
          refetchClusters={refetchClusters}
          close={handleCloseModal}
          handleShowErrorModal={handleShowErrorModal}
        />
      );
    case modalKeys.AUDIT_HISTORY:
      return (
        <Modal
          title="Audit History"
          open={true}
          onCancel={handleCloseModal}
          footer={null}
          width={'90%'}
        >
          <AuditHistory instance={instance} userID={null} />
        </Modal>
      );
    case modalKeys.PROGRESS:
      return (
        <Modal
          title="Progress Logs"
          open={true}
          onCancel={handleCloseModal}
          footer={null}
          width={'50%'}
        >
          <ProgressLogs instance={instance} close={handleCloseModal} />
        </Modal>
      );
    case modalKeys.FEATURE_BITS:
      return (
        <FeatureBitsActionsModal
          instanceFeatureBits={instance.featureBits.map(
            featureBit => featureBit.featureBitID,
          )}
          clusterIds={[instance.id]}
          refetchClusters={refetchClusters}
          close={handleCloseModal}
          handleShowErrorModal={handleShowErrorModal}
        />
      );
    case modalKeys.SECRETS:
      return (
        <Modal
          title="Cluster Secrets"
          open={true}
          onCancel={handleCloseModal}
          footer={null}
          width={'50%'}
        >
          <Secrets instance={instance} />
        </Modal>
      );
    case modalKeys.USER_ROLES:
      return (
        <Modal
          title="List of users"
          open={true}
          onCancel={handleCloseModal}
          footer={null}
          width={'60%'}
        >
          <InstanceUsers cluster={instance} />
        </Modal>
      );
    case modalKeys.CUSTOMIZE:
      return <CustomizeModal instance={instance} close={handleCloseModal} />;
    case modalKeys.SLA_COST:
      return (
        <SlaCostModal
          isNew={false}
          cluster={instance}
          refetchClusters={refetchClusters}
          close={handleCloseModal}
        />
      );
    case modalKeys.CLUSTER_MAINTENANCE:
      return (
        <ClusterMaintenanceModal
          isNew={false}
          cluster={instance}
          refetchClusters={refetchClusters}
          close={handleCloseModal}
        />
      );
    case modalKeys.ERROR:
      return (
        <ErrorModal
          close={() => {
            setActiveModal(null);
            setError(null);
          }}
        />
      );
    case modalKeys.SCHEDULE:
      return (
        <SchedulerModal
          isNew={false}
          cluster={instance}
          refetchClusters={refetchClusters}
          close={handleCloseModal}
        />
      );
    case modalKeys.LOCK_WINDOW:
      return (
        <LockInterfaceModal
          cluster={instance}
          close={handleCloseModal}
          handleShowErrorModal={handleShowErrorModal}
          refetchClusters={refetchClusters}
        />
      );
    case modalKeys.MASS_LOCK_INTERFACE:
      return (
        <LockInterfaceModal
          clusterIds={selectedRowKeys}
          close={handleCloseModal}
          handleShowErrorModal={handleShowErrorModal}
          refetchClusters={refetchClusters}
        />
      );
    case modalKeys.SPARK_MASTER:
      return (
        <Modal
          className="chidori-modal__wrapper"
          title={
            <div className="chidori-modal__title">
              <img src={ChidoriIcon} alt="Chidori" /> Chidori
            </div>
          }
          open={true}
          onCancel={handleCloseModal}
          footer={null}
          width={'50%'}
        >
          <SparkMasterModal instance={instance} close={handleCloseModal} />
        </Modal>
      );
    case modalKeys.NOTEBOOK:
      return (
        <Modal
          className="notebook-modal__wrapper"
          title={
            <div className="notebook-modal__title">
              <img src={NotebookIcon} alt="Notebook" /> Notebook for cluster{' '}
              <strong>{instance.name}</strong>
            </div>
          }
          open={true}
          onCancel={handleCloseModal}
          footer={null}
          width={'50%'}
          maskClosable={false}
        >
          <NotebookModal
            instanceID={instance.id}
            isClusterRunning={instance.status === 'running'}
            clusterSize={instance.analyticsSize}
            isChidori={instance.InstanceChidori?.isChidori}
            enableBusinessUserNotebook={instance.enableBusinessUserNotebook}
            originalNotebookUsersCount={instance.businessUserNotebookCount}
            platform={instance.platform}
            close={handleCloseModal}
          />
        </Modal>
      );
    case modalKeys.AUTO_SCALING:
      return (
        <AutoScalingModal
          instance={modalCount === 'single' ? instance : null}
          onCancel={handleCloseModal}
        />
      );
    case modalKeys.DISK_UPSIZE:
      return (
        <DiskUpsizeModal
          instance={modalCount === 'single' ? instance : null}
          refetchClusters={refetchClusters}
          close={handleCloseModal}
        />
      );
    case modalKeys.EXTERNAL_BUCKET:
      return (
        <ExternalBucketModal
          close={handleCloseModal}
          instanceID={instance.id}
          clusterName={instance.name}
        />
      );

    default:
      return <></>;
  }
}

export default ClustersModalsWrapper;
