import React, { useState } from 'react';
import './ClusterSizeCellModal.less';
import { Button, Form, Input, Modal, StepProps, Steps, message } from 'antd';
import { FormattedMessage } from 'react-intl';
import {
  CheckOutlined,
  ClusterOutlined,
  InfoCircleOutlined,
  LeftOutlined,
  RadarChartOutlined,
  RightOutlined,
  UpCircleOutlined,
} from '@ant-design/icons';
import { useForm } from 'antd/lib/form/Form';
import { NamePath } from 'antd/lib/form/interface';
import { FormInstance } from 'antd/es/form';
import DeleteClusterSizeAction from '../DeleteClusterSizeAction/DeleteClusterSizeAction';
import ClusterSizeOvercommitmentStep from '../Steps/ClusterSizeOvercommitmentStep/ClusterSizeOvercommitmentStep';
import ClusterSizeMainInfoStep from '../Steps/ClusterSizeMainInfoStep/ClusterSizeMainInfoStep';
import ClusterSizeOperatorsStep from '../Steps/ClusterSizeOperatorsStep/ClusterSizeOperatorsStep';
import ClusterSizeAffectedInstancesStep from '../Steps/ClusterSizeAffectedInstancesStep/ClusterSizeAffectedInstancesStep';
import { ClusterSizesPerClusterCodeItem } from 'types/capacityManagement.types';
import {
  useCreateNewClusterSize,
  useUpdateClusterSize,
} from 'hooks/capacityManagementQueries';

export const fieldNames: Record<string, NamePath> = {
  name: 'name',
  displayName: 'displayName',
  nodeLabel: 'nodeLabel',
  roles: 'rolesIDs',
  memoryRequest: 'memoryRequest',
  memoryLimit: 'memoryLimit',
  memoryText: 'memoryText',
  ipu: 'ipu',
  cpu: 'cpu',
  icc: 'icc',
  order: 'order',
  canBeDeleted: 'canBeDeleted',
  defaultSize: 'defaultSize',
  overCommitment: 'overCommitment',
  overCommitmentEnabled: ['overCommitment', 'isEnabled'],
  overCommitmentLoaderLabel: ['overCommitment', 'loaderLabel'],
  overCommitmentLoaderValue: ['overCommitment', 'loaderValue'],
  overCommitmentAnalyticsLabel: ['overCommitment', 'analyticsLabel'],
  overCommitmentAnalyticsValue: ['overCommitment', 'analyticsValue'],
  platform: ['regionProvider', 'cloudProviderID'],
  createdAt: 'createdAt',
};

export type SelectedOperators = {
  id: number;
  region: string;
  clusterCode: string;
  nodepools:
    | { label: string; memoryLimit: number; memoryText: number }[]
    | null;
  checked: boolean;
  label: string;
  memoryLimit: number;
  memoryText: number;
  memoryRequest: number;
  cpu: number;
};

type ClusterSizeCellModalProps = {
  clusterSizeData?: ClusterSizesPerClusterCodeItem & { operatorAlias: string };
  cloudProviderID?: number;
  cloudProviderKey?: string;
  close: () => void;
};
function ClusterSizeCellModal({
  clusterSizeData,
  cloudProviderID,
  cloudProviderKey,
  close,
}: ClusterSizeCellModalProps) {
  const isCreateNewSize = !clusterSizeData;

  const initialValues = {
    ...(isCreateNewSize ? {} : { ...clusterSizeData }),
  };

  const [current, setCurrent] = useState(0);

  const [clusterSizeFormValues, setClusterSizeFormValues] = useState<any>({
    regionProvider: {
      cloudProviderID,
    },
  });

  const [operatorsDataSource, setOperatorsDataSource] = useState<any[]>();
  const clusterCodeID = clusterSizeData?.regionProvider?.id;
  const [selectedOperatorsIDs, setSelectedOperatorsIDs] = useState<number[]>(
    clusterCodeID ? [clusterCodeID] : [],
  );

  function handleCloseModal() {
    setSelectedOperatorsIDs([]);
    close();
  }

  const {
    mutateAsync: mutateCreateNewClusterSize,
    isLoading: isCreateNewClusterSizeLoading,
  } = useCreateNewClusterSize();
  const {
    mutateAsync: mutateUpdateClusterSize,
    isLoading: isUpdateClusterSizeLoading,
  } = useUpdateClusterSize();

  const next = () => {
    setCurrent(current + 1);
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  const [form] = useForm<any>();

  const steps = [
    {
      title: 'Main Info',
      content: () => (
        <ClusterSizeMainInfoStep
          isCreateNewSize={isCreateNewSize}
          form={form}
          initialValues={initialValues as any} // FIXME:
        />
      ),
      description: 'Name & Roles',
      icon: <InfoCircleOutlined />,
    },
    {
      title: 'Operators',
      content: () => (
        <ClusterSizeOperatorsStep
          isCreateNewSize={isCreateNewSize}
          form={form}
          initialValues={initialValues}
          cloudProviderID={
            cloudProviderID || clusterSizeData?.regionProvider?.cloudProviderID
          }
          cloudProviderKey={cloudProviderKey}
          clusterCode={clusterSizeData?.regionProvider?.clusterCode}
          clusterCodeID={clusterCodeID}
          operatorsDataSource={operatorsDataSource}
          setOperatorsDataSource={setOperatorsDataSource}
          selectedOperatorsIDs={selectedOperatorsIDs}
          setSelectedOperatorsIDs={setSelectedOperatorsIDs}
        />
      ),
      description: 'Platform, Operators & node labels',
      icon: <RadarChartOutlined />,
    },
    ...(!isCreateNewSize
      ? [
          {
            title: 'Over Commitment',
            content: () => (
              <ClusterSizeOvercommitmentStep
                isCreateNewSize={isCreateNewSize}
                form={form}
                initialValues={initialValues}
              />
            ),
            description: '',
            icon: <UpCircleOutlined />,
          },
          {
            title: 'Instances',
            content: () => (
              <ClusterSizeAffectedInstancesStep
                clusterSizeID={clusterSizeData.id}
              />
            ),
            description: 'Affected Clusters',
            icon: <ClusterOutlined />,
          },
        ]
      : []),
  ];

  const isLastStep = current === steps.length - 1;

  const items: StepProps[] = steps.map(item => ({
    key: item.title,
    title: item.title,
    description: item.description,
    icon: item.icon,
  }));

  const onChange = (value: number) => {
    console.log('onChange:', current);
    setCurrent(value);
  };

  async function handleNext() {
    try {
      const values = await form.validateFields();

      const isOverCommitmentStep =
        current === steps.findIndex(step => step.title === 'Over Commitment');
      if (!!isOverCommitmentStep) {
        let { overCommitment } = values;
        overCommitment = {
          analyticsValue: overCommitment.isEnabled
            ? overCommitment.analyticsValue || false
            : false,
          excluded: !overCommitment.isEnabled,
          loaderValue: overCommitment.isEnabled
            ? overCommitment.loaderValue || false
            : false,
        };
        values.overCommitment = overCommitment;
      }

      let operatorClusterCodes: {
        code: string;
        label: string;
        memoryRequest: number;
        memoryLimit: number;
        memoryText: number;
        cpu: number;
      }[] = [];
      const isOperatorsStep = !!(
        current === steps.findIndex(step => step.title === 'Operators')
      );
      if (isOperatorsStep) {
        let { selectedOperators } = values;
        operatorClusterCodes = (selectedOperators as SelectedOperators[])
          .filter(op => op.checked)
          .map(op => ({
            code: op.clusterCode,
            label: op.label,
            memoryRequest: op.memoryRequest,
            memoryLimit: op.memoryLimit,
            memoryText: op.memoryText,
            cpu: op.cpu,
          }));
      }

      // console.log(clusterSizeFormValues);
      // console.log(operatorsDataSource);
      setClusterSizeFormValues(prev => ({ ...prev, ...values }));

      if (isLastStep) {
        if (isCreateNewSize) {
          const newClusterSize = {
            name: clusterSizeFormValues.name,
            displayName: clusterSizeFormValues.displayName,
            cloudProviderID:
              clusterSizeFormValues.regionProvider.cloudProviderID,
            rolesIDs: clusterSizeFormValues.rolesIDs,
            operatorClusterCodes,
          } as any;
          console.log(newClusterSize);
          const { status } = await mutateCreateNewClusterSize({
            newClusterSize,
          });
          if (status.toString().startsWith('2')) {
            close();
          }
        } else {
          const updatedClusterSize = {
            cpu: operatorsDataSource?.[0]?.cpu,
            memoryLimit: operatorsDataSource?.[0]?.memoryLimit,
            memoryRequest: operatorsDataSource?.[0]?.memoryRequest,
            memoryText: operatorsDataSource?.[0]?.memoryText,
            rolesIDs: clusterSizeFormValues.rolesIDs,
            nodeLabel: clusterSizeFormValues?.selectedOperators?.[0]?.label,
            overCommitment: clusterSizeFormValues.overCommitment,
          };
          if ('id' in initialValues) {
            const { status } = await mutateUpdateClusterSize({
              sizeID: initialValues.id,
              updatedClusterSize,
            });
            if (status.toString().startsWith('2')) {
              close();
            }
          }
        }
      } else {
        form.submit();
        setTimeout(() => {
          next();
        }, 100);
      }
    } catch (error) {
      console.log(error);
    }
  }
  function handlePrev() {
    prev();
  }

  return (
    <Form.Provider
      onFormFinish={name => {
        if (name === 'ClusterSizeMainInfoStep') {
          // Do something...
        }
      }}
    >
      <Modal
        open
        centered
        title={
          <section className="cluster-size-cell-modal__title-wrapper">
            <span>
              {isCreateNewSize ? (
                <FormattedMessage id="cloudCapacityManagement.sizeModal.titleNewSize" />
              ) : (
                <FormattedMessage
                  id="cloudCapacityManagement.sizeModal.title"
                  values={{
                    displayName: <b>{clusterSizeData?.displayName}</b>,
                    operator: <b>{clusterSizeData?.operatorAlias}</b>,
                  }}
                />
              )}
            </span>
          </section>
        }
        onCancel={handleCloseModal}
        width={1024}
        className="cluster-size-cell-modal__wrapper"
        footer={
          <section className="cluster-size-cell-modal__footer-wrapper">
            {!isCreateNewSize ? (
              <DeleteClusterSizeAction
                clusterSizeID={clusterSizeData?.id}
                clusterSizeDisplayName={clusterSizeData?.displayName}
                operatorAlias={clusterSizeData?.operatorAlias}
                isLoading={
                  isCreateNewClusterSizeLoading || isUpdateClusterSizeLoading
                }
                canBeDeleted={clusterSizeData?.canBeDeleted}
                close={close}
              />
            ) : (
              <span />
            )}

            <section className="cluster-size-cell-modal__footer-navigation-actions">
              <Button
                key="back"
                onClick={handlePrev}
                disabled={
                  current === 0 ||
                  isCreateNewClusterSizeLoading ||
                  isUpdateClusterSizeLoading
                }
                icon={<LeftOutlined />}
              >
                Previous
              </Button>
              <Button
                key="next"
                type="primary"
                icon={isLastStep ? <CheckOutlined /> : <RightOutlined />}
                loading={
                  isCreateNewSize
                    ? isCreateNewClusterSizeLoading
                    : isUpdateClusterSizeLoading
                }
                onClick={handleNext}
              >
                {isLastStep
                  ? isCreateNewSize
                    ? 'Create Size'
                    : 'Modify Size'
                  : 'Next'}
              </Button>
            </section>
          </section>
        }
      >
        <Steps
          size="small"
          current={current}
          items={items}
          // onChange={onChange} // this enables clickin on Steps header for navigation
        />

        <section className="steps-content__wrapper">
          <div className="steps-content">{steps[current].content()}</div>
        </section>
      </Modal>
    </Form.Provider>
  );
}

export default ClusterSizeCellModal;
