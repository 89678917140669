import React, { useEffect, useState } from 'react';
import './ClusterSizeOperatorsStep.less';
import {
  Form,
  FormInstance,
  Input,
  InputNumber,
  Row,
  Select,
  Table,
  Tag,
  Tooltip,
} from 'antd';
import FormItem from 'antd/es/form/FormItem';
import { ColumnsType } from 'antd/lib/table';
import { AlertOutlined } from '@ant-design/icons';
import Icon from '@ant-design/icons/lib/components/Icon';
import { fieldNames } from '../../ClusterSizeCellModal/ClusterSizeCellModal';
import {
  useGetCloudProviders,
  useGetOperatorsPerPlatform,
} from 'hooks/capacityManagementQueries';
import { InstancePlatform } from 'types/cluster';
import { OperatorPerPlatformItem } from 'types/capacityManagement.types';
import useCloudProvider from 'hooks/useCloudProvider';

const maxMemoryValue = 10000;
const maxCPUValue = 1000;

function ClusterSizeOperatorsStep({
  isCreateNewSize,
  form,
  initialValues,
  cloudProviderID,
  cloudProviderKey,
  clusterCode,
  clusterCodeID,
  operatorsDataSource,
  setOperatorsDataSource,
  selectedOperatorsIDs,
  setSelectedOperatorsIDs,
}: {
  isCreateNewSize: boolean;
  form?: FormInstance<any>; // FIXME:
  initialValues?: any;
  cloudProviderID?: number;
  cloudProviderKey?: string;
  clusterCode?: string;
  clusterCodeID?: number;
  operatorsDataSource: any[];
  setOperatorsDataSource: React.Dispatch<React.SetStateAction<any[]>>;
  selectedOperatorsIDs: number[];
  setSelectedOperatorsIDs: React.Dispatch<React.SetStateAction<number[]>>;
}) {
  const { Option } = Select;

  const { CloudProviderLogoMap } = useCloudProvider();

  if (!Object.keys(initialValues).length && cloudProviderID) {
    // initialValues.cloudProviderID = cloudProviderID;
    initialValues.regionProvider = {
      cloudProviderID,
    };
  }

  const { data: cloudProviders } = useGetCloudProviders();
  const [currentCloudProviderKey, setCurrentCloudProviderKey] = useState(
    cloudProviderKey ||
      cloudProviders?.find(
        cloudProvider => cloudProvider.id === cloudProviderID,
      ).key,
  );

  const {
    data: operatorsPerPlatformResult,
    isLoading: isOperatorsPerPlatformLoading,
    isError: hasOperatorsPerPlatformError,
  } = useGetOperatorsPerPlatform({
    platform: currentCloudProviderKey as InstancePlatform,
    ...(clusterCode
      ? {
          operatorNameFilter: clusterCode,
        }
      : {}),
  });
  const operatorsPerPlatform =
    operatorsPerPlatformResult?.data?.platformOperators;
  // const nodePools =
  //   operatorsPerPlatformResult?.data?.platformOperators?.[0]?.nodepools;

  useEffect(() => {
    if (!!operatorsPerPlatform && Array.isArray(operatorsPerPlatform)) {
      // FIXME: Temp to handle duplicate node label
      // const currentNodeLabel = operatorsPerPlatform?.[0]?.nodepools?.find(
      //   nodePool => nodePool?.label === initialValues?.nodeLabel,
      // );
      const selectedOperatorsValue = operatorsPerPlatform
        .filter(op =>
          currentCloudProviderKey !== InstancePlatform.GC || !isCreateNewSize
            ? true
            : true,
        )
        ?.map(op => ({
          ...op,
          checked: isCreateNewSize ? false : true,
          ...(!isCreateNewSize && initialValues
            ? {
                label: initialValues.nodeLabel,
                cpu: initialValues.cpu,
                // FIXME: Temp to handle duplicate node label
                // ...(currentNodeLabel
                //   ? {
                //       vCPUs: currentNodeLabel?.vCPUs,
                //     }
                //   : {}),
                memoryRequest: initialValues.memoryRequest,
                memoryLimit: initialValues.memoryLimit,
              }
            : {}),
        }));
      form.setFieldValue('selectedOperators', selectedOperatorsValue);
    }
  }, [operatorsPerPlatform]);

  useEffect(() => {
    if (operatorsPerPlatform?.length) {
      const currentNodeLabel = operatorsPerPlatform?.[0]?.nodepools?.find(
        nodePool => nodePool?.label === initialValues?.nodeLabel,
      );
      setOperatorsDataSource(
        operatorsPerPlatform?.map(op => ({
          ...op,
          code: op.clusterCode,
          label: initialValues.nodeLabel,
          cpu: initialValues.cpu,
          ...(!isCreateNewSize && initialValues && currentNodeLabel
            ? {
                vCPUs: currentNodeLabel?.vCPUs,
              }
            : {}),
          memoryLimit: !isCreateNewSize ? initialValues.memoryLimit : 1,
          memoryText: !isCreateNewSize ? initialValues.memoryText : 1,
          // FIXME: Temp to handle duplicate node label
          // memoryRequest: !isCreateNewSize ? initialValues.memoryRequest : 1,
          ...(!isCreateNewSize && initialValues.memoryRequest
            ? { memoryRequest: initialValues.memoryRequest }
            : {}),
        })),
      );
    }
  }, [currentCloudProviderKey, operatorsPerPlatform]);

  const columns: ColumnsType<
    OperatorPerPlatformItem & {
      memoryRequest: number;
      memoryText: number;
      memoryLimit: number;
      label: string;
      cpu: number;
      code: string;
    }
  > = [
    {
      title: 'Operator',
      dataIndex: 'clusterCode',
    },
    {
      title: 'Node Label',
      dataIndex: 'nodeLabel',
      width: 200,
      ellipsis: true,
      render(rowValue, { nodepools, clusterCode, region }, index) {
        // FIXME: Temp to handle duplicate node label
        if (true) {
          // if (currentCloudProviderKey !== InstancePlatform.GC) {
          return (
            <Input
              placeholder="Input a label"
              defaultValue={initialValues.nodeLabel}
              onChange={async e => {
                const val = e.target.value;
                const currentID = operatorsDataSource[index].id;

                const selectedOperatorsValue = await form.getFieldValue(
                  'selectedOperators',
                );
                const updatedSelectedOperatorsValueAfterChangingLabel =
                  selectedOperatorsValue.map(op => {
                    if (op.id === currentID) {
                      return {
                        ...op,
                        label: val,
                      };
                    }
                    return op;
                  });
                form.setFieldValue(
                  'selectedOperators',
                  updatedSelectedOperatorsValueAfterChangingLabel,
                );
                form.validateFields();

                const newDataSource = operatorsDataSource.map(item => {
                  if (item.id === currentID) {
                    return {
                      ...item,
                      label: (e.target as any).value,
                      // memoryLimit: option.memoryLimit,
                      // memoryText: option.memoryText,
                    };
                  }
                  return item;
                });
                setOperatorsDataSource(newDataSource);
              }}
            />
          );
        }
        if (!nodepools?.length) {
          if (!isCreateNewSize) {
            return initialValues.nodeLabel;
          }
          return (
            <section className="operators-step__node-labels-cell-wrapper">
              <Tooltip title="No node labels available">
                <AlertOutlined /> Configure Labels
              </Tooltip>
            </section>
          );
        }
        return (
          <Select
            placeholder="Select one label"
            defaultValue={initialValues.nodeLabel}
            options={nodepools?.map(nodePool => ({
              label: nodePool.label,
              value: nodePool.label,
              memoryLimit: nodePool.memoryLimit,
              memoryText: nodePool.memoryText,
              vCPUs: nodePool.vCPUs,
            }))}
            disabled={!nodepools?.length}
            showSearch
            filterOption={(input, option) =>
              option?.label
                ?.toString()
                .toLowerCase()
                .includes(input.toLowerCase())
            }
            onChange={async (
              value: string,
              option: {
                label: string;
                value: string;
                memoryLimit: number;
                memoryText: number;
                vCPUs: number;
              },
            ) => {
              const currentID = operatorsDataSource[index].id;
              const selectedOperatorsValue = await form.getFieldValue(
                'selectedOperators',
              );
              const updatedSelectedOperatorsValueAfterSelectingNodelLabel =
                selectedOperatorsValue.map(op => {
                  if (op.id === currentID) {
                    return {
                      ...op,
                      label: option.label,
                      memoryRequest: 1,
                      memoryLimit: option.memoryLimit,
                      memoryText: option.memoryText,
                      vCPUs: option.vCPUs,
                    };
                  }
                  return op;
                });
              form.setFieldValue(
                'selectedOperators',
                updatedSelectedOperatorsValueAfterSelectingNodelLabel,
              );
              form.validateFields();

              const newDataSource = operatorsDataSource.map(item => {
                if (item.id === currentID) {
                  return {
                    ...item,
                    label: option.label,
                    memoryRequest: 1,
                    memoryLimit: option.memoryLimit,
                    memoryText: option.memoryText,
                    vCPUs: option.vCPUs,
                  };
                }
                return item;
              });
              setOperatorsDataSource(newDataSource);
            }}
          >
            {nodepools?.map(nodePool => (
              <Option
                key={nodePool.label}
                value={nodePool.label}
                label={nodePool.label}
              >
                <Tag>{nodePool.label}</Tag>
              </Option>
            ))}
          </Select>
        );
      },
    },
    {
      title: 'CPU',
      dataIndex: 'cpu',
      align: 'center',
      render(value, { label }, index) {
        // FIXME: Temp to handle duplicate node label
        // const vCPUss = operatorsDataSource[index].nodepools?.find(
        //   nodePool => nodePool.label === label,
        // )?.vCPUs;
        // const max = vCPUs ? Math.min(vCPUs, maxCPUValue) : maxCPUValue;
        const max = maxCPUValue;
        return (
          <InputNumber
            defaultValue={value}
            value={value}
            min={1}
            max={max}
            type="number"
            onChange={async val => {
              const currentID = operatorsDataSource[index].id;
              const selectedOperatorsValue = await form.getFieldValue(
                'selectedOperators',
              );
              const updatedOperatorsValueAfterChangingCPU =
                selectedOperatorsValue.map(op => {
                  if (op.id === currentID) {
                    if (val >= 1 && val <= max) {
                      return {
                        ...op,
                        cpu: val,
                      };
                    } else {
                      return op;
                    }
                  }
                  return op;
                });
              form.setFieldValue(
                'selectedOperators',
                updatedOperatorsValueAfterChangingCPU,
              );
              form.validateFields();

              const newDataSource = operatorsDataSource.map(item => {
                if (item.id === currentID) {
                  return {
                    ...item,
                    cpu: val,
                  };
                }
                return item;
              });
              setOperatorsDataSource(newDataSource);
            }}
          />
        );
      },
    },
    // {
    //   title: 'Max CPU',
    //   dataIndex: 'vCPUs',
    //   align: 'center' as any,
    //   width: 50,
    // },
    {
      title: 'Memory',
      children: [
        {
          title: 'Limit',
          dataIndex: 'memoryLimit',
          align: 'center',
          render(value, { nodepools }, index) {
            // FIXME: Temp to handle duplicate node label
            if (true) {
              // if (currentCloudProviderKey !== InstancePlatform.GC) {
              return (
                <InputNumber
                  defaultValue={initialValues?.memoryLimit}
                  min={1}
                  max={maxMemoryValue}
                  type="number"
                  onChange={async val => {
                    const currentID = operatorsDataSource[index].id;
                    const selectedOperatorsValue = await form.getFieldValue(
                      'selectedOperators',
                    );
                    const updatedSelectedOperatorsValueAfterChangingMemoryLimit =
                      selectedOperatorsValue.map(op => {
                        if (op.id === currentID) {
                          if (val >= 1 && val <= maxMemoryValue) {
                            return {
                              ...op,
                              ...(val < op.memoryRequest
                                ? {
                                    memoryRequest: val,
                                  }
                                : {}),
                              memoryLimit: val,
                              ...(currentCloudProviderKey !==
                              InstancePlatform.GC
                                ? {
                                    memoryText: +val + 2,
                                  }
                                : {}),
                            };
                          } else {
                            return op;
                          }
                        }
                        return op;
                      });
                    form.setFieldValue(
                      'selectedOperators',
                      updatedSelectedOperatorsValueAfterChangingMemoryLimit,
                    );
                    form.validateFields();

                    const newDataSource = operatorsDataSource.map(item => {
                      if (item.id === currentID) {
                        return {
                          ...item,
                          memoryLimit: val,
                          memoryText: val,
                          ...(val < item.memoryRequest
                            ? {
                                memoryRequest: val,
                              }
                            : {}),
                        };
                      }
                      return item;
                    });
                    setOperatorsDataSource(newDataSource);
                  }}
                />
              );
            }

            if (
              currentCloudProviderKey === InstancePlatform.GC &&
              !nodepools?.length
            ) {
              return initialValues.memoryLimit;
            } else {
              const isLabelSelected = !!operatorsDataSource[index].label;
              return isLabelSelected ? value : '';
            }
          },
        },
        {
          title: 'Request',
          dataIndex: 'memoryRequest',
          align: 'center',
          render(value, { memoryLimit, nodepools }, index) {
            return (
              <InputNumber
                defaultValue={value}
                value={value}
                min={1}
                max={memoryLimit}
                type="number"
                // FIXME: Temp to handle duplicate node label
                // disabled={
                //   currentCloudProviderKey === InstancePlatform.GC &&
                //   isCreateNewSize &&
                //   !nodepools?.length
                // }
                onChange={async val => {
                  const currentID = operatorsDataSource[index].id;
                  const selectedOperatorsValue = await form.getFieldValue(
                    'selectedOperators',
                  );
                  const updatedOperatorsValueAfterChangingMemoryRequest =
                    selectedOperatorsValue.map(op => {
                      if (op.id === currentID) {
                        if (val >= 1 && val <= maxMemoryValue) {
                          return {
                            ...op,
                            memoryRequest: val,
                          };
                        } else {
                          return op;
                        }
                      }
                      return op;
                    });
                  form.setFieldValue(
                    'selectedOperators',
                    updatedOperatorsValueAfterChangingMemoryRequest,
                  );
                  form.validateFields();

                  const newDataSource = operatorsDataSource.map(item => {
                    if (item.id === currentID) {
                      return {
                        ...item,
                        memoryRequest: val,
                      };
                    }
                    return item;
                  });
                  setOperatorsDataSource(newDataSource);
                }}
              />
            );
          },
        },
        // ...(currentCloudProviderKey === InstancePlatform.GC
        //   ? [
        //       {
        //         title: 'Text',
        //         dataIndex: 'memoryText',
        //         width: 50,
        //         align: 'center' as any,
        //         render(value, { nodepools }, index) {
        //           if (
        //             currentCloudProviderKey === InstancePlatform.GC &&
        //             !nodepools?.length
        //           ) {
        //             return initialValues.memoryText;
        //           } else {
        //             const isLabelSelected = !!operatorsDataSource[index].label;
        //             return isLabelSelected ? value : '';
        //           }
        //         },
        //       },
        //     ]
        //   : []),
      ],
    },
  ];

  const onOperatorsSelectionChange = async (
    selectedRowIds: number[],
    selectedRows: OperatorPerPlatformItem[],
  ) => {
    const selectedOperatorsValue = await form.getFieldValue(
      'selectedOperators',
    );
    const updatedOperatorsValueAfterSelectionChange =
      selectedOperatorsValue.map(op => {
        const isChecked = selectedRowIds.includes(op.id);
        return { ...op, checked: isChecked };
      });
    form.setFieldValue(
      'selectedOperators',
      updatedOperatorsValueAfterSelectionChange,
    );
    form.validateFields();
    setSelectedOperatorsIDs(selectedRowIds);
  };

  return (
    <Form
      name="ClusterSizeOperatorsStep"
      initialValues={initialValues}
      form={form}
    >
      <FormItem
        name={fieldNames.platform}
        label="Platform"
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 10 }}
        required
      >
        <Select
          disabled={!isCreateNewSize}
          options={cloudProviders
            ?.filter(
              cloudProvider =>
                ![InstancePlatform.AWS, InstancePlatform.AZURE].includes(
                  cloudProvider.key as InstancePlatform,
                ),
            )
            ?.map(cloudProvider => ({
              label: (
                <article
                  key={cloudProvider.id}
                  className="operators-step__platform-select-label"
                >
                  {CloudProviderLogoMap.get(
                    cloudProvider.key as InstancePlatform,
                  ) || <Icon />}
                  {cloudProvider.displayName}
                </article>
              ),
              value: cloudProvider.id,
              cloudPrvdrKey: cloudProvider.key,
            }))}
          showSearch
          filterOption={(input, option) =>
            option?.label
              ?.toString()
              .toLowerCase()
              .includes(input.toLowerCase())
          }
          onChange={(
            value: number,
            option: {
              label: JSX.Element;
              value: number;
              cloudPrvdrKey: string;
            },
          ) => {
            setCurrentCloudProviderKey(option.cloudPrvdrKey);
            setSelectedOperatorsIDs([]);
          }}
        />
      </FormItem>
      <FormItem
        name="selectedOperators"
        label="Operators"
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 20 }}
        className="operators-step__operators-form-item"
        required
        rules={[
          {
            validator: async () => {
              const currentOperators = await form.getFieldValue(
                'selectedOperators',
              );
              const isAnyOperatorSelected =
                Array.isArray(selectedOperatorsIDs) &&
                selectedOperatorsIDs.length > 0;
              const isAllSelectedOperatorsFilled =
                !!currentOperators?.length &&
                currentOperators
                  .filter(operator =>
                    selectedOperatorsIDs.includes(operator.id),
                  )
                  .every(
                    operator =>
                      operator.label &&
                      operator.memoryRequest &&
                      operator.memoryLimit &&
                      operator.cpu,
                  );
              if (!isAnyOperatorSelected) {
                return Promise.reject('Select an Operator');
              }
              if (!isAllSelectedOperatorsFilled) {
                return Promise.reject('Fill the fields');
              }
              return Promise.resolve();
            },
          },
        ]}
      >
        <Table
          size="small"
          columns={columns}
          dataSource={operatorsDataSource}
          rowKey="id"
          loading={isOperatorsPerPlatformLoading}
          rowSelection={{
            type: 'checkbox',
            selectedRowKeys: selectedOperatorsIDs,
            preserveSelectedRowKeys: true,
            onChange: onOperatorsSelectionChange,
            getCheckboxProps: (record: {
              id: number;
              region: string;
              clusterCode: string;
              nodepools: {
                label: string;
                memoryLimit: number;
                memoryText: number;
              }[];
              code: string;
              memoryLimit: number;
              memoryText: number;
              memoryRequest: number;
            }) => {
              return {
                disabled: !isCreateNewSize
                  ? true
                  : // FIXME: Temp to handle duplicate node label
                    false,
                // currentCloudProviderKey === InstancePlatform.GC &&
                // !record.nodepools?.length,
              };
            },
          }}
          pagination={false}
        />
      </FormItem>
    </Form>
  );
}

export default ClusterSizeOperatorsStep;
